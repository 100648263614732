import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import UtahArtsFestivalImage from '../../assets/images/publication-banners/utah-arts-festival.jpg';

const UtahArtsFestival = (props) => (
  <Layout>
    <Helmet>
      <title>Utah Arts Festival - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and event information for Utah Arts Festival’s guide to the festival. Please contact Mills Publishing for availability and rates. "
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Utah Arts Festival</h1>
          </header>
          <span className="image main">
            <img src={UtahArtsFestivalImage} alt="" />
          </span>
          <p className="box">
            The Utah Arts Festival remains one of the premiere events to kick
            off the summer! The program for the festival is handed to each
            patron as he/she enters the festival. It is also pre-distributed to
            key locations in downtown Salt Lake City and various locations
            across the valley.
            <br />
            <br />
            The festival is the largest outdoor multidisciplined event in Utah.
            It has received numerous awards internationally, nationally, and
            locally. Last year, it had an approximate attendance of 80,000
            patrons!
            <br />
            <br />
            The program is a necessary tool for patrons attending the festival.
            It provides a map, entertainment and performance schedules, contact
            information and other pertinent information. Not only is the program
            handed to visitors at the festival entrance throughout the weekend,
            the program is also distributed at 50 locations throughout Downtown
            and the greater Salt Lake area prior to the festival for maximum
            exposure. The program also provides an opportunity for advertisers
            to be exposed to a marketplace of outgoing people who support
            independent artists, local restaurateurs, and small merchants and
            businesses throughout the community.
            <br />
            <br />
            Patrons who attend the festival enjoy four days of award-winning
            fine arts in the Artists Marketplace, live music and entertainment
            on five stages, local and international street theatre, film
            programs, exhibition and culinary delights. The festival also
            invites children to participate in the hands-on projects at the
            Target Art Yard. Teenagers will also be able to explore his/her
            creative sides at the Urban Arts Venue.
            <br />
            <br />
            Mills Publishing invites you to join the fun this year and advertise
            in this high quality publication!
          </p>
          <ul className="actions">
            <li>
              <a href="https://uaf.org/" className="button special">
                <i className="icon fa-globe"> </i>Utah Arts Festival Website
              </a>
            </li>
          </ul>
          <h2>Utah Arts Festival {new Date().getFullYear()} Information</h2>
          <p>
            <em>
              Dates are subject to change. Please contact us if you have any
              questions.
            </em>
          </p>
          <div className="grid-wrapper">
            <div className="col-6 box">
              <h3>TBD</h3>
              <p>
                <strong>TBD</strong> <br />
                TBD
                <br />
                <br />
                <h5>Advertising Information</h5>
                <section className="box">
                  File Ready Deadline: TBD <br />
                  Circulation: TBD
                </section>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default UtahArtsFestival;
